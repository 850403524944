import { PermissionsConjunction } from 'frontend-container/components/Menu/authorization/types';
import { MenuElement } from 'frontend-container/components/Menu/types';
import { getBusinessContextData } from 'frontend-container/shared/businessContext/getBusinessContext';

import { customerPermissionKeys, FeatureToggleName } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';
import { IconName } from '@ac/web-components';

const identityPermissions = customerPermissionKeys.identity;

export const getSystemUsersMenu = (): MenuElement => {
  const businessContextData = getBusinessContextData();

  const featureToggles = businessContextData?.system?.featureToggles ?? [];
  const featureToggle = featureToggles.find(
    ({ featureName }) =>
      featureName === FeatureToggleName.EmberToReactSystemRolesEmberEnabled
  );

  const systemRoleLink = `${
    featureToggle?.enabled
      ? acConfig.newFrontendUrls.configuration
      : acConfig.newFrontendUrls.configurationv2
  }/system-roles`;

  const systemUserLink = `${acConfig.newFrontendUrls.configurationv2}/system-users`;

  return {
    translation: 'MENU.SYSTEM_USERS.TITLE',
    icon: IconName.profile,
    id: 'menu-users',
    items: [
      {
        link: systemUserLink,
        aliases: [`${systemUserLink}/new`, `${systemUserLink}/:userId/details`],
        translation: 'MENU.SYSTEM_USERS.ITEMS.LIST',
        permissionsConfiguration: {
          permissions: [identityPermissions.viewUsers],
          permissionsConjunction: PermissionsConjunction.And,
        },
      },
      {
        link: systemRoleLink,
        translation: 'MENU.SYSTEM_USERS.ITEMS.SYSTEM_ROLES',
        permissionsConfiguration: {
          permissions: [identityPermissions.viewRoles],
          permissionsConjunction: PermissionsConjunction.And,
        },
      },
    ],
  };
};
